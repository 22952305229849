<template>
  <form>
    <div class="row">
      <loading-notification :show="loading" />
      <error-notification :show="isError.months">
        <span>{{ $t('reports.errors.team_already_reported') }}</span>
      </error-notification>
    </div>
    <div class="row">
      <div class="flex xs12 md6">
        <team-bar
          :loading="loading || isLoading.teams"
          :disabled="loading"
          :with-filters="teamFilter"
          :initial-team="initialTeam"
          @selected-team="teamSelected"
        />
      </div>
      <div class="flex xs12 md6">
        <va-select
          v-model="reportMonth"
          :label="$t('reports.inputs.monthInput')"
          :options="monthsList"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          :loading="isLoading.months"
          text-by="name"
          searchable
        />
      </div>
    </div>
    <location-bar
      :disabled="true"
      :loading="loading"
      :with-filters="['regions', 'countries', 'districts']"
      :initial-district="initialDistrict"
      @selected-district="adjustDistrict"
    />
    <div class="row">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.district_manager.value"
          :disabled="loading"
          :label="$t('reports.inputs.districtManagerInput')"
          :error="!!form.district_manager.errors.length"
          :error-messages="translatedErrors(form.district_manager.errors)"
          @blur="validate('district_manager')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.mother_church.value"
          :disabled="loading"
          :label="$t('reports.inputs.churchInput')"
          :error="!!form.mother_church.errors.length"
          :error-messages="translatedErrors(form.mother_church.errors)"
          @blur="validate('mother_church')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.district_coordinator.value"
          :disabled="loading"
          :label="$t('reports.inputs.districtCoordinatorInput')"
          :error="!!form.district_coordinator.errors.length"
          :error-messages="translatedErrors(form.district_coordinator.errors)"
          @blur="validate('district_coordinator')"
        />
      </div>
    </div>
    <div class="row" v-if="wizard">
      <h6 class="flex xs12">
        {{ $t('reports.inputs.type.title') }}
        <va-button
          flat
          small
          color="primary"
          icon="fa fa-plus"
          @click.prevent="openPreachingModal"
        >
          {{ $t('tables.actions.new') }}
        </va-button>
      </h6>
    </div>
    <va-modal
      v-if="wizard"
      ref="preachingModal"
      v-model="preachingModal"
      :max-width="'100vw'"
      :title="$t('layout.modals.new_preaching_point')"
      :hide-default-actions="true"
      :fullscreen="true"
      @cancel="closePreachingModal"
    >
      <preaching-modal
        :preaching-code="preachingCode"
        :preaching-leader="preachingLeader"
        @submit="addPreachingPoint"
      />
    </va-modal>

    <div class="row" v-if="wizard">
      <div class="flex xs12">
        <va-select
          v-model="selectedPreachingPoints"
          :label="$t('layout.form.selected_preachingInput')"
          :no-options-text="$t('layout.empty')"
          :loading="isLoading.preachingPoints"
          :disabled="loading"
          :options="preachingPointsList"
          text-by="label"
          searchable
          multiple
        />
      </div>
    </div>

    <!--
    <div class="row">
      <div class="flex xs12 md6">
        <va-input
          color="info"
          v-model="form.type_name.value"
          :disabled="loading"
          :label="$t('reports.inputs.type.name')"
          :error="!!form.type_name.errors.length"
          :error-messages="translatedErrors(form.type_name.errors)"
          @blur="validate('type_name')"
        />
      </div>
      <div class="hidden">
        <va-input
          color="info"
          v-model="form.type_district.value"
          :disabled="loading"
          :label="$t('reports.inputs.type.district')"
          :error="!!form.type_district.errors.length"
          :error-messages="translatedErrors(form.type_district.errors)"
        />
      </div>
      <div class="flex xs12 md6">
        <va-input
          color="info"
          v-model="form.type_minister.value"
          :disabled="loading"
          :label="$t('reports.inputs.type.leader')"
          :error="!!form.type_minister.errors.length"
          :error-messages="translatedErrors(form.type_minister.errors)"
          @blur="validate('type_minister')"
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          type="number"
          step="1"
          min="0"
          v-model="form.type_asistance.value"
          :disabled="loading"
          :label="$t('reports.inputs.type.assistance')"
          :error="!!form.type_asistance.errors.length"
          :error-messages="translatedErrors(form.type_asistance.errors)"
          @blur="validate('type_asistance')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.preaching_code.value"
          :label="$t('reports.inputs.type.code')"
          :error="!!form.preaching_code.errors.length"
          :error-messages="translatedErrors(form.preaching_code.errors)"
          @blur="validate('preaching_code')"
          disabled
        />
      </div>
      <div class="flex xs12 md4">
        <va-date-picker
          id="preaching-date"
          :label="$t('reports.inputs.type.date')"
          :config="dateConfig"
          v-model="preachingDate"
        />
      </div>
    </div>
    -->

    <div class="row">
      <h5 class="flex xs12">{{ $t('reports.tabs.requests.main') }}</h5>
      <hr>
      <div class="flex xs12">
        <tabs-container :tabs="tabs">
          <template v-slot:original>
            <div class="flex xs12">
              <va-input
                color="info"
                v-model="form.testimony_es.value"
                type="textarea"
                :autosize="true"
                :min-rows="5"
                :disabled="loading"
                :label="$t('reports.inputs.testimoniesInput')"
                :error="!!form.testimony_es.errors.length"
                :error-messages="translatedErrors(form.testimony_es.errors)"
                @blur="validate('testimony_es')"
              />
            </div>
          </template>
          <template v-slot:translated>
            <div class="row">
              <div class="flex xs12">
                <va-input
                  color="info"
                  v-model="form.testimony_en.value"
                  type="textarea"
                  :autosize="true"
                  :min-rows="5"
                  :disabled="loading"
                  :label="$t('reports.inputs.testimoniesInput')"
                  :error="!!form.testimony_en.errors.length"
                  :error-messages="translatedErrors(form.testimony_en.errors)"
                />
              </div>
              <div
                class="flex xs12"
                v-if="currentUser.can('Translate', 'translate')"
              >
                <va-button
                  type="button"
                  color="primary"
                  :disabled="isLoading.translation"
                  @click.prevent="translate()"
                >
                  <text-loading
                    :loading="isLoading.translation"
                    icon="entypo entypo-language"
                  >
                    {{ $t('tables.actions.translate_to_en') }}
                  </text-loading>
                </va-button>
              </div>
            </div>
          </template>
        </tabs-container>
      </div>
    </div>
    <div
      class="row"
      v-if="!wizard"
    >
      <div class="flex xs12 sm2">
        <va-button
          color="primary"
          type="button"
          :disabled="loading"
          @click.prevent="submit()"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
      <div class="flex xs12 sm4">
        <va-button
          v-if="canReview"
          color="success"
          type="button"
          :disabled="loading"
          @click.prevent="saveAndReview(1)"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-check"
          >
            {{ $t('reports.inputs.review.district.approve') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>
<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
import subMonths from 'date-fns/subMonths'
import setDay from 'date-fns/setDay'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
const LocationBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Location')
const TeamBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Team')
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const PreachingModal = () => import(/* webpackPrefetch: true */ '@/components/extras/PreachingModal')

export default {
  name: 'reports-form',
  components: {
    TeamBar,
    LocationBar,
    TabsContainer,
    PreachingModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    wizard: {
      type: Boolean,
      default: false,
    },
    report: {
      type: Object,
      required: false,
      default: () => { },
    },
    maxPreachingPoints: {
      type: Number,
      default: () => 1,
    },
    previousMonths: {
      type: Number,
      default: () => 3,
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      isLoading: {
        translation: false,
        teams: false,
        months: false,
        preachingPoints: false,
      },
      isError: {
        teams: false,
        months: false,
        form: false,
        preachingPoints: false,
      },
      preachingPointsList: [],
      selectedPreachingPoints: [],
      preachingModal: false,
      preachingDate: null,
      preachingLeader: null,
      preachingCode: '',
      dateConfig: {},
      monthsList: [],
      notExpired: false,
      reportMonth: '',
      teamFilter: '',
      initialTeam: null,
      selectedTeam: null,
      initialDistrict: null,
      selectedDistrict: null,
      form: {
        district_manager: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        mother_church: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        district_coordinator: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        testimony_es: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        testimony_en: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    isNew () {
      return !this.report.id
    },
    canReview () {
      return this.currentUser.can('Proyections', 'approve') && this.notExpired
    },
    tabs () {
      const t = [
        { title: this.$t('reports.tabs.requests.original_title'), name: 'original' },
        { title: this.$t('reports.tabs.requests.en_title'), name: 'translated' },
      ]
      return t
    },
  },
  watch: {
    report (val) {
      this.setReport(val)
    },
    selectedTeam (val) {
      if (this.reportMonth !== '') {
        this.checkTeamMonthReport()
      }
      this.asyncFindPreaching()
    },
    reportMonth (val) {
      if (this.selectedTeam) {
        this.checkTeamMonthReport()
      }
      this.asyncFindPreaching()
    },
    previousMonths (val) {
      this.adjustMonths()
    },
    async currentLocale (val) {
      this.adjustMonths()

      await this.$nextTick()
      this.validateAll()
    },
  },
  created () {
    this.initialData()
    this.adjustMonths()
    if (this.currentUser.can('PreachingPoints', 'index')) {
      this.asyncFindPreaching()
    }
  },
  methods: {
    async setReport (val) {
      this.setFormData(val)
      if (val.team) {
        this.initialTeam = val.team
      }
      if (val.proyection_date) {
        const date = this.$date.format(val.proyection_date, 'MMMM - yyyy')
        let current = this.monthsList.find(m => m.name === date)
        if (!current) {
          current = {
            id: 0,
            name: date,
            date: val.proyection_date,
          }
        }

        this.reportMonth = current
      }
      if (val.preaching_date) {
        this.preachingDate = val.preaching_date
      }
      if (val.expiration_date) {
        const expired = parseISO(val.expiration_date)
        this.notExpired = isBefore(new Date(), expired)
      }
      if (val.district) {
        this.selectedDistrict = val.district
        this.initialDistrict = this.selectedDistrict
        if (!this.report.type_district) {
          this.report.type_district = val.district.name
        }
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.report) {
        this.setReport(this.report)
      }
    },
    async asyncFindPreaching () {
      if (!this.currentUser.can('PreachingPoints', 'index')) return

      let query = ''
      if (this.selectedDistrict) {
        query += `&district=${this.selectedDistrict.id}`
      }
      if (this.reportMonth) {
        query += `&date_since${this.reportMonth.date}`
      }
      if (this.selectedTeam) {
        query += `&team=${this.selectedTeam.id}`
      }
      query += '&status=0'

      await this.asyncFind(query, 'preachingPoints', 'preaching-points')
      this.preachingPointsList = this.preachingPointsList.filter(x => x.proyections.length === 0)
      for (const p of this.preachingPointsList) {
        p.label = `${p.code} ${p.name}`
      }
      if (this.isError.preachingPoints) {
        return this.asyncFindPreaching(query)
      }
    },
    openPreachingModal () {
      this.preachingModal = true
    },
    closePreachingModal () {
      this.preachingModal = false
    },
    generatePreachingCode () {
      let code = ''
      if (this.selectedTeam) {
        code = this.selectedTeam.code
      }
      let month = ''
      let year = ''
      if (this.reportMonth) {
        if (typeof this.reportMonth.date === 'string') {
          this.reportMonth.date = this.reportMonth.date.slice(0, 10)
          this.reportMonth.date = new Date(parseISO(this.reportMonth.date))
        }
        month = this.reportMonth.date.getMonth() + 1
        year = this.reportMonth.date.getFullYear()
      }
      return `${code}${year}${month}`
    },
    teamSelected (team) {
      this.selectedTeam = team
      this.adjustTeam(team)

      this.$emit('team-selected', team)
      this.preachingCode = this.generatePreachingCode()
    },
    async translate () {
      this.isLoading.translation = true

      const text = this.getFormValue('testimony_es')
      const body = {
        text: text,
      }
      let response = false
      try {
        response = await this.$http.post('/translate', body)
      } catch (err) {
        this.isLoading.translation = false
        this.showToast(this.$t('notifications.translate.error'), {
          icon: 'fa-times',
          position: 'top-right',
        })
        return
      }

      const translation = response.data.data || []
      if (translation.length > 0) {
        this.report.testimony_es = text
        this.report.testimony_en = translation[0].translations[0].text
      }
      this.setFormData(this.report)
      this.isLoading.translation = false
      this.validateAll()
    },
    async asyncFind (query, type, route) {
      this.isLoading[type] = true
      this.isError[type] = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        this.isError[type] = true
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    async adjustTeam (team, id) {
      if (!team.district) return

      this.selectedDistrict = team.district
      this.initialDistrict = this.selectedDistrict
      for (const p of this.preachingPointsList) {
        if (p.new) {
          p.type_district = this.selectedDistrict.name
        }
      }

      if (this.selectedDistrict.manager) {
        this.report.district_manager = this.selectedDistrict.manager.name
      }
      if (this.selectedDistrict.coordinator) {
        this.report.district_coordinator = this.selectedDistrict.coordinator.name
      }
      if (team.leader) {
        this.report.type_minister = team.leader.name
        this.preachingLeader = team.leader
      }
      this.setFormData(this.report)

      await this.$nextTick()
      this.validateAll()
    },
    async adjustDistrict (district) {
      this.selectedDistrict = district
      this.report.type_district = district.name

      if (district.manager) {
        this.report.district_manager = district.manager.name
      }
      if (district.coordinator) {
        this.report.district_coordinator = district.coordinator.name
      }
      this.getTeamsDistrictBased(district)

      await this.$nextTick()
      this.validateAll()
    },
    getTeamsDistrictBased (district) {
      this.teamFilter = '&district=' + district.id
    },
    addPreachingPoint (preachingPoint) {
      preachingPoint.new = true
      preachingPoint.type_district = this.selectedDistrict.name
      preachingPoint.label = preachingPoint.preaching_code + ' ' + preachingPoint.type_name
      this.selectedPreachingPoints.push(preachingPoint)
      this.closePreachingModal()
    },
    async saveAndReview (status) {
      const report = await this.submit(true)
      if (!report) return

      const data = { approve: status || 0, type: 'districts' }

      this.$emit('approval', { report: report, approve: data })
    },
    async submit (unpropagate) {
      this.isError.form = false
      unpropagate = unpropagate || false

      this.validateAll()
      if (!this.formReady || this.isError.months) {
        const enTestimony = this.getFormValue('testimony_en')
        if (!enTestimony) {
          this.showToast(this.$t('notifications.alerts.testimony_translation_needed'), {
            icon: 'fa-times',
            position: 'top-right',
          })
        }
        this.isError.form = true
        return false
      }

      const report = this.getFormData(this.report)
      const preachingPoints = this.selectedPreachingPoints.slice(0)

      if (this.selectedDistrict) {
        report.district_id = this.selectedDistrict.id
      }
      delete report.district

      if (this.reportMonth) {
        report.proyection_date = this.$date.format(this.reportMonth.date, 'yyyy-MM-01')
      }

      if (this.preachingDate) {
        report.preaching_date = this.$date.format(this.preachingDate, 'yyyy-MM-dd')
      }

      if (this.selectedTeam) {
        report.team_id = this.selectedTeam.id
      }
      delete report.team

      if (report.presentations) {
        delete report.presentations
      }

      if (report.preaching_points) {
        delete report.preaching_points
      }

      if (!unpropagate) {
        this.$emit('submit', { report, preachingPoints })
      }
      return report
    },
    adjustMonths () {
      const months = []
      const current = setDay(new Date(), 1)
      const current2 = new Date()

      const currentMonth = {
        id: 2,
        name: this.$date.format(current, 'MMMM - yyyy'),
        date: current,
      }
      months.push(currentMonth)

      const currentMonth1 = {
        id: 1,
        name: this.$date.format(current2, 'MMMM - yyyy'),
        date: current,
      }
      months.push(currentMonth1)

      for (let i = 0; i < this.previousMonths; i++) {
        const before = subMonths(current, i + 1)
        const beforeMonth = {
          id: i + 3,
          name: this.$date.format(before, 'MMMM - yyyy'),
          date: before,
        }
        months.unshift(beforeMonth)
      }

      this.monthsList = months
    },
    async checkTeamMonthReport () {
      this.isLoading.teams = true
      this.isLoading.months = true

      const code = this.selectedTeam.id
      const date = this.$date.format(this.reportMonth.date, 'yyyy-MM-01')
      let response = false
      try {
        response = await this.$http.get(`proyections?team=${code}&date=${date}`)
      } catch (e) {
        console.log('Error while trying to get proyections', e)
        this.isLoading.teams = false
        this.isLoading.months = false
        return
      }

      let error = false
      if (response.data.data.length > 0) {
        const id = this.report.id || 0
        if (id !== response.data.data[0].id) {
          error = true
        }
      }
      this.isError.months = error
      this.isLoading.teams = false
      this.isLoading.months = false
      this.preachingCode = this.generatePreachingCode()
    },
  },
}
</script>
<style scoped>
.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
